.landingDrawer-cont {
    width: 100%;
    padding: 20px;
    background: url("../../assets/frame3.png") no-repeat !important;
    background-color: #24213a !important;
    height: 100%;

    &__wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        justify-content: center;

        &__titleCont {
            width: 100%;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 8px;
            background-color: #373451;
            padding: 16px 12px;
            height: 72px;

            &__img {
                width: 100%;
            }

            &__btn-close {
                width: 24px;
                height: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: transparent;
                border: none;
                cursor: pointer;
                font-size: 20px;
                color: #fff;
                text-align: center;
                font-weight: bold;
                transition: background-color 0.5s ease, box-shadow 0.5s ease;
                border-radius: 4px;

                &:hover {
                    /* Ant Design primary color on hover */
                    background-color: #24213a;
                    /* Light hover background */
                }

                &:active {
                    /* Ant Design primary color on active */
                    background-color: #24213a
                        /* Slightly darker background on active */
                }

                &:focus {
                    outline: none;
                    /* Remove focus outline */
                }
            }
        }

        &__content {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 16px;

            &__box {
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: space-between;
                gap: 8px;
                background: rgba(71, 66, 112, 0.3);
                border: none;
                padding: 16px 12px;
                border-radius: 12px;
                cursor: pointer;

                >a {
                    color: rgba(255, 255, 255);
                    font-size: 22px;
                    font-weight: 500;
                    line-height: 24px;
                    text-decoration: none;
                }
            }
        }
    }

    &__btnsWrapper {
        display: flex;
        gap: 12px;
        align-items: center;
        justify-content: space-between;
        margin-top: auto;
        border-top: 2px solid rgba(71, 66, 112, 0.3);
        padding-top: 10px;

        >button:first-child {
            padding: 8px;
            font-family: "Barlow";
            border: none;
            background-color: transparent;
            color: rgba(255, 255, 255, 0.8);
            font-size: 18px;
            font-weight: 500;
            line-height: 22px;
            width: 80px;
            border-radius: 6px;
        }

        >button:nth-child(2) {
            font-family: "Barlow";
            border-radius: 6px;
            padding: 8px;
            border: none;
            background: #52a212;
            color: #fff;
            font-size: 18px;
            font-weight: 500;
            line-height: 22px;
            outline: none;
            width: 80px;
        }

        >button:nth-child(3) {
            font-family: "Barlow";
            border-radius: 6px;
            padding: 8px;
            border: none;
            background: rgba(173, 62, 240, 255);
            color: #fff;
            font-size: 18px;
            font-weight: 500;
            line-height: 22px;
            outline: none;
            width: 80px ;
        }
    }

    &__active-box {

        border-top: 2px solid;
        border-color: rgba(128, 192, 77, 1);

        >a {
            color: rgba(128, 192, 77, 1)
        }
    }

    .tooltip-btn {
        position: relative;
        border: none;
        padding: 8px;
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        width: 100%;
        border-radius: 6px;
        cursor: pointer;
    }

    .tooltip-text {
        visibility: hidden;
        background-color: #373451;
        color: #fff;
        text-align: center;
        padding: 6px;
        border-radius: 4px;
        position: absolute;
        bottom: 120%;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        opacity: 0;
        transition: opacity 0.3s;
        &::after {
            content: "";
            position: absolute;
            top: 100%; /* Position it at the bottom */
            left: 50%;
            transform: translateX(-50%);
            border-width: 6px;
            border-style: solid;
            border-color: #373451 transparent transparent transparent; /* Triangle shape */
        }
    }

    .tooltip-btn:hover .tooltip-text {
        visibility: visible;
        opacity: 1;
    }
}

@media (max-width: 768px) {
    .landingDrawer-cont {
        &__wrapper {
            &__titleCont {
                height: 56px;
            }
        }
    }
}