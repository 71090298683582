.barcodeModal-cont {
  border-radius: 8px;
  width: 100%;
  max-width: 880px;

  &__titleWrapper {
    width: 100%;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: space-between;

    &__title {
      margin: 0;
      font-weight: 600;
      font-size: 16px;
      line-height: 1.5;
      word-wrap: break-word;
      margin-right: 8px;
    }
  }

  &__btn {
    width: 100%;
    max-width: 80px;
    padding: 0px 16px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;

    &__formItem {
      width: 100%;

      &__searchWrapper {
        width: 100%;
        display: flex;
        gap: 8px;
        align-items: center;
      }

      &__text {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
     
    }
  }

  &__table {
    width: 100%;
  }

  .ant-form-item-required.ant-form-item-no-colon::before {
    content: "" !important;
  }

  &__diplayNone {
    display: none;
  }
}


@media screen and (max-width: 900px) {
  .barcodeModal-cont {
    max-width: 600px;
  }
}

@media screen and (max-width: 600px) {
  .barcodeModal-cont {
    max-width: 400px;
  }
}

@media screen and (max-width: 400px) {
  .barcodeModal-cont {
    max-width: 300px;

    &__titleWrapper {
      flex-direction: column;
      align-items: flex-start;

    }
  }
}