.deleteorder-cont {
  width: 100%;
  max-width: 400px;
  border-radius: 8px;

  &__titleWrapper {
    display: flex;
    gap: 16px;
    width: 100%;

    &__title {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }

    &__icon {
      font-size: 24px;
    }
  }

  &__btn {
    width: 100%;
    max-width: 120px;
    padding: 0px 16px;
  }

  &__subTitle {
    font-size: 16px;
    font-style: bold;
    font-weight: 600;
    line-height: 22px;
  }

  &__radiogroup {
    display: flex;
    flex-direction: column;
    gap: 8;
    label {
      margin-top: 4px;
    }
    margin-top: 4px;
    margin-bottom: 4px;
  }
}